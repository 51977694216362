import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { compose } from "redux";
import { REGISTER_SUCCESS } from "../actions/types";
import { Header } from "../components/layout/Header";
import SignLogo from "../static/images_frontend/sign_logo.png";

export class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            email: '',
            states: [],
            selectedState: '',
        };
    }

    componentDidMount() {
        axios.get(`${API}${VERSION}retrieve/available/states`)
            .then(response => {
                const states = response.data;
                this.setState({
                    states,
                    selectedState: states.length ? states[0] : ''
                });
            })
            .catch(error => {
                console.error("Error fetching states:", error);
            });

        // Focus effects
        const emailGroup = document.getElementById("email-group");
        const passwordGroup = document.getElementById("password-group");

        emailGroup?.addEventListener("focusin", () => emailGroup.classList.add("focused"));
        passwordGroup?.addEventListener("focusin", () => passwordGroup.classList.add("focused"));
    }

    onChangePassword = (e) => {
        this.setState({ password: e.target.value });
    }

    onChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    onChangeState = (e) => {
        this.setState({ selectedState: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { password, email, selectedState } = this.state;
        const { alert } = this.props;
        const payload = {
            password,
            email,
            state: selectedState
        };

        const config = { headers: { 'Content-Type': 'application/json' } };
        const body = JSON.stringify(payload);

        axios.post(`${API}${VERSION}auth/register`, body, config)
            .then(response => {
                const data = response.data;
                alert.show(data.messages[0]);
                this.props.dispatch({ type: REGISTER_SUCCESS, payload: data });
                this.props.history.push('/');
            })
            .catch(error => {
                const errors = error.response?.data?.errors;
                alert.error(errors ? errors[0] : "An error occurred.");
            });
    }

    render() {
        const { password, states, selectedState } = this.state;
        const { auth } = this.props;

        return (
            <main className="main-area dmt_test">
                <Header auth={auth} />
                <div className="around__area">
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-6" style={{ background: "#F9F9F9" }}>
                                <div className="around_im">
                                    <img src={SignLogo} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 order-1 order-md-0 bac_f9" style={{ background: "#fff" }}>
                                <div className="aside__part sm_part">
                                    <div className="sign_form">
                                        <h2>Register</h2>
                                        <div className="form-wrapper">
                                            <form method="POST" className="form" autoComplete="off">
                                                <div className="form-group" id="email-group">
                                                    <label className="form-label" htmlFor="first">Email address</label>
                                                    <input id="first" className="form-input" type="email"
                                                           required="" onChange={this.onChangeEmail}
                                                           autoComplete="new-email" />
                                                </div>
                                                <div className="form-group" id="password-group">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input id="password" type="password"
                                                           className="form-control form-input"
                                                           name="password" value={password}
                                                           autoComplete="new-password"
                                                           onChange={this.onChangePassword} />
                                                </div>
                                                <div className="form-group">
                                                    <p className="new_p_font">Choose your state</p>
                                                    <select id="state" className="form-control"
                                                            value={selectedState} onChange={this.onChangeState}>
                                                        {states.map(state => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <br/>
                                                <p className="new_p">
                                                    Already have an account? <Link to="/login">Sign in</Link>
                                                </p>
                                                <button type="submit" onClick={this.onSubmit}>Create account</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(RegisterPage);
