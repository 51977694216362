import React, { Component } from 'react';
import { API, VERSION } from "../routers/Urls";
import axios from "axios";
import { Header } from "../components/layout/Header";
import { connect } from "react-redux";

class UploadVerificationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            testGroupContext: {},
            questionsLoaded: false,
            isLoading: true,
            error: null,
        };
    }

    componentDidMount() {
        this.loadQuestions();
    }

    loadQuestions() {
        const { state, year, version } = this.props.match.params;

        // Make the API request with the new parameters
        axios.get(`${API}${VERSION}test-questions/${state}/${year}/${version}`)
            .then(response => {
                this.setState({
                    questionsLoaded: true,
                    questions: response.data.questions,
                    testGroupContext: response.data.testGroupContext,
                    isLoading: false,  // Set loading to false when data is loaded
                    error: null, // Reset any previous errors
                });
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    // Handle 404 error
                    this.setState({
                        isLoading: false,
                        error: "Quiz not found. Please check the parameters and try again.", // Custom error message
                    });
                } else {
                    // Handle other errors
                    console.error("Error loading questions:", error);
                    this.setState({
                        isLoading: false,
                        error: "An error occurred while loading the quiz. Please try again later.", // General error message
                    });
                }
            });
    }

    render() {
        const { questions, testGroupContext, questionsLoaded, isLoading, error } = this.state;
        const auth = this.props.auth;

        return (
            <main className="main-area">
                <Header auth={auth} />
                <div className="result__details">
                    <div className="container">
                        {/* Show a loader while the data is loading */}
                        {isLoading && (
                            <div className="loader-container">
                                <div className="loader"/> {/* Loader div */}
                            </div>
                        )}

                        {/* Display error message if any */}
                        {error && (
                            <div className="error-message">
                                <p>{error}</p> {/* Display the error message */}
                            </div>
                        )}

                        {/* Render the test group context and questions */}
                        {!isLoading && !error && (
                            <>
                                <div className="result_content">
                                    <h2>Test Questions</h2>
                                    <p>{testGroupContext.state} - {testGroupContext.year} - Version {testGroupContext.version}</p>
                                    <p>Created On: {new Date(testGroupContext.createdAt).toLocaleDateString()}</p>
                                </div>

                                {questionsLoaded && (
                                    <div className="row">
                                        {questions.map((question, question_index) => (
                                            <div className="col-lg-12 col-md-6" key={question.id}>
                                                <div className="quistion_area">
                                                    <h5>{question.title}</h5>
                                                    <div className="row">
                                                        {question.imageUrl && (
                                                            <div className="building_im text-end">
                                                                <img className='question_image' src={question.imageUrl} alt="Question" />
                                                            </div>
                                                        )}
                                                        <div className="col-lg-12">
                                                            <div className="select_ans">
                                                                <ul>
                                                                    {question.options.map((option, option_index) => {
                                                                        let checkIcon = '';
                                                                        if (option.userChoice) {
                                                                            checkIcon = <div className="check ck_danger"><div className="inside" /></div>;
                                                                        } else if (option.isRight) {
                                                                            checkIcon = <div className="check ck_active" />;
                                                                        } else {
                                                                            checkIcon = <div className="check" />;
                                                                        }

                                                                        return (
                                                                            <li key={option.id}>
                                                                                <input type="radio" id={`${question.id}-${option.id}`} disabled={true} checked={option.isRight} />
                                                                                {option.imageUrl ? (
                                                                                    <label htmlFor={`${question.id}-${option.id}`} style={{ margin: '-9px auto' }}>
                                                                                        <img className='option_image' src={option.imageUrl} alt="Option" />
                                                                                    </label>
                                                                                ) : (
                                                                                    <label htmlFor={`${question.id}-${option.id}`}>
                                                                                        {option.text}
                                                                                    </label>
                                                                                )}
                                                                                {checkIcon}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(UploadVerificationPage);
