import React, { Component } from 'react';
import { Header } from "../components/layout/Header";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import store from "../store/configureStore";
import { LOGOUT_SUCCESS } from "../actions/types";

export class AccountPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            profilePicture: '',
            isMember: false,
            loaded: false,
            states: [],
            selectedState: ''
        }
    };

    componentDidMount() {
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json' } };
        if (token) { config.headers['Authorization'] = `Token ${token}`; }

        // Fetch user information including states and selected state
        axios.get(`${API}${VERSION}auth/user`, config).then(r => {
            const data = r.data;
            const user = data.user;
            this.setState({
                email: user.email,
                isMember: user.isMember,
                selectedState: data.userSelectedState,
                states: data.states,
                loaded: true
            });
        }).catch(e => {
            // Handle error and log out the user
            store.dispatch({ type: LOGOUT_SUCCESS });
            this.props.history.push('/login');
        });
    }

    // Handle email change
    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({ email });
    }

    // Handle password change
    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({ password });
    }

    // Handle state change
    onChangeState = (e) => {
        const selectedState = e.target.value;
        this.setState({ selectedState });
    }

    // Handle form submission to update user info
    onSubmit = (e) => {
        let payload = {};
        const { email, password, selectedState } = this.state;

        if (email) payload["email"] = email;
        if (password) payload["password"] = password;
        if (selectedState) payload["state"] = selectedState;

        const token = this.props.auth.token;
        const alert = this.props.alert;
        const config = { headers: { 'Content-Type': 'application/json' } };
        if (token) { config.headers['Authorization'] = `Token ${token}`; }

        axios.patch(`${API}${VERSION}auth/user/update`, payload, config).then(r => {
            const data = r.data;
            const messages = data.messages[0];
            alert.show(messages);
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    // Render the component
    render() {
        const auth = this.props.auth;
        const loaded = this.state.loaded;
        const { email, password, states, selectedState } = this.state;
        return (
            <main className="main-area">
                <Header auth={auth} />
                {loaded && (
                    <div>
                        <div className="container">
                            <div className="account__box">
                                <div className="row">
                                    <div className="col-md-12 col-8">
                                        <div className="result_content">
                                            <h2>Account</h2>
                                            <p>Update and manage your account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="clr_bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="edit__account">
                                                <p>Edit your account</p>
                                                <form action="#" method="POST" className="form" autoComplete="off">
                                                    {/* Email input field */}
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="first">Email</label>
                                                        <input onChange={this.onChangeEmail} id="first" value={email}
                                                               className="form-input" type="email" />
                                                    </div>
                                                    {/* Password input field */}
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="password1">
                                                            New password
                                                        </label>
                                                        <input onChange={this.onChangePassword} id="password1"
                                                               autoComplete="new-password" type="password"
                                                               value={password} className="form-control form-input"
                                                               name="password" />
                                                    </div>
                                                    {/* State dropdown */}
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="state">State</label>
                                                        <select id="state" value={selectedState} onChange={this.onChangeState} className="form-input">
                                                            {states.map((state, index) => (
                                                                <option key={index} value={state}>
                                                                    {state}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="refound_profile">
                                        <div className="row clr_bg2">
                                            <div className="col-md-3">
                                                {/* Save changes button */}
                                                <a onClick={this.onSubmit} className="save_a">Save Changes</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(AccountPage);
